/**
 * Create a GEOJson Circle for mapbox
 * Source: https://stackoverflow.com/a/39006388
 */

// Source: https://stackoverflow.com/a/1253545
const LAT_TO_KM_CONVERSION_RATE = 110.574;
const LNG_TO_KM_CONVERSION_RATE = 111.320;
export const createGeoJSONCircle = ( center, radiusInKm, pts ) => {
	const points = pts || 128;

	const coords = {
		latitude: center[1],
		longitude: center[0],
	};

	const km = radiusInKm;

	const ret = [];
	const distanceX = km / ( LNG_TO_KM_CONVERSION_RATE * Math.cos( coords.latitude * Math.PI / 180 ) );
	const distanceY = km / LAT_TO_KM_CONVERSION_RATE;

	let theta; let x; let
		y;
	for ( let i = 0; i < points; i++ ) {
		theta = ( i / points ) * ( 2 * Math.PI );
		x = distanceX * Math.cos( theta );
		y = distanceY * Math.sin( theta );

		ret.push( [
			coords.longitude + x,
			coords.latitude + y,
		] );
	}
	ret.push( ret[0] );

	return {
		type: 'geojson',
		data: {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Polygon',
						coordinates: [
							ret,
						],
					},
				},
			],
		},
	};
};

export const getCoordinatesWithHorizontalDistanceFromPoint = ( point, distanceInKm ) => {
	const coords = {
		latitude: point[1],
		longitude: point[0],
	};

	const distanceX = distanceInKm / ( LNG_TO_KM_CONVERSION_RATE * Math.cos( coords.latitude * Math.PI / 180 ) );

	return [
		coords.longitude - distanceX,
		coords.latitude,
	];
};

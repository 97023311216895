/*
 * Mr Window Watcher 🖥👀
 *
 * Sending out Custom Events to be (re-)used in JS
 * Setting body class variations to be used in CSS
 *
 * -----
 *
 * Elements we need:
 *
 * The <body>, because state modifier classes are added to it
 *
 * -----
 *
 * Events we listen for:
 *
 * A: Scroll Event
 * B: Resize Event
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-window-watcher', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.body = document.querySelector( 'body' );

			this.scrollHandler( window.pageYOffset );
		}

		// [A]
		scrollHandler( scrollPosition ) {
			if ( isNaN( scrollPosition ) || !this.elements.body ) {
				return;
			}

			if ( isNaN( this.currentScrollPosition ) ) {
				this.currentScrollPosition = 0;
			}

			let scrollDirection = 'down';

			if ( scrollPosition < this.currentScrollPosition ) {
				scrollDirection = 'up';
			}

			if ( 50 < scrollPosition ) {
				this.elements.body.classList.add( 'is-scrolled' );
			} else {
				this.elements.body.classList.remove( 'is-scrolled' );
			}

			this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:scroll', {
				bubbles: true,
				cancelable: true,
				detail: {
					direction: scrollDirection,
					position: scrollPosition,
					scrolled: false,
				},
			} ) );

			this.currentScrollPosition = scrollPosition;
		}

		bind() {
			// [A]
			let scrollThrottle = false;

			this.on( 'scroll', () => {
				if ( !this.elements.body ) {
					this.unbind( 'scroll' );

					return;
				}

				if ( scrollThrottle ) {
					return;
				}

				scrollThrottle = true;
				this.scrollHandler( window.pageYOffset );

				setTimeout( () => {
					scrollThrottle = false;
					this.scrollHandler( window.pageYOffset );
				}, 96 );
			}, window, {
				passive: true,
			} );

			// [B]
			let resizeThrottle = false;

			this.on( 'resize', () => {
				if ( resizeThrottle ) {
					return;
				}

				resizeThrottle = true;

				setTimeout( () => {
					resizeThrottle = false;

					this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:resize', {
						bubbles: true,
						cancelable: true,
					} ) );
				}, 240 );
			}, window, {
				passive: true,
			} );
		}
	},
} );

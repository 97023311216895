/*
 * Mr Gallery 🖼
 *
 * Gallery with 'click to show next' interaction + indicator buttons
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-gallery', {
	attributes: [
		{
			attribute: 'auto',
			type: 'int',
		},
		{
			attribute: 'unclickable-slides',
			type: 'boolean',
		},
		{
			attribute: 'length',
			type: 'int',
		},
	],
	controller: class extends BaseController {
		currentChangedCallback() {
			this.checkIfInScreen();
			this.start();
			this.render();
		}

		get current() {
			return parseFloat( this.el.getAttribute( 'current' ) );
		}

		set current( to ) {
			const parsed = parseFloat( to );

			if ( Number.isNaN( parsed ) ) {
				console.warn( `Could not set ${'current'} to ${to}` );
				this.el.removeAttribute( 'current' );
			} else {
				this.el.setAttribute( 'current', parsed );
			}

			this.currentChangedCallback();
		}

		autoChangedCallback() {
			this.start();
		}

		start() {
			this.stop();
			this.startInterval();
		}

		stop() {
			if ( this.looper ) {
				clearInterval( this.looper );
				this.looper = null;
			}
		}

		next() {
			if ( this.current < this.length - 1 ) {
				this.current = this.current + 1;
			} else {
				this.current = 0;
			}
		}

		previous() {
			if ( 0 < this.current ) {
				this.current = this.current - 1;
			} else {
				this.current = this.length - 1;
			}
		}

		resolve() {
			if ( this.length && 1 >= this.length ) {
				// Keep hanging, don't activate if empty
				return new Promise( () => {} );
			}

			return super.resolve();
		}

		init() {
			this.elements = {};
			this.elements.buttons = Array.from( this.el.querySelectorAll( '.js-gallery-button' ) );
			this.elements.items = Array.from( this.el.querySelectorAll( '.js-gallery-item' ) );

			this.checkIfInScreen();
			this.start();
		}

		bind() {
			this.on( 'click .js-gallery-item', ( e ) => {
				if ( e.target.classList.contains( 'js-gallery-link' ) ) {
					// This is the title link
					// do nothing
					return;
				}
				e.preventDefault();
				this.next();

				if ( this.looper ) {
					clearInterval( this.looper );
					this.startInterval();
				}
			} );

			if ( this.unclickableSlides ) {
				this.unbind( 'click .js-gallery-item' );
			}

			this.on( 'click .js-gallery-button', ( e, target ) => {
				e.preventDefault();

				const index = parseFloat( target.getAttribute( 'index' ) );

				if ( null === index || isNaN( index ) ) {
					return;
				}

				this.current = index;

				if ( this.looper ) {
					clearInterval( this.looper );
					this.startInterval();
				}
			} );

			this.on( 'mr-window-watcher:scroll', ( e ) => {
				if ( 'undefined' !== typeof e.detail.position ) {
					this.checkIfInScreen( e.detail.position );
				}
			}, document.body );
		}

		render() {
			this.elements.items.forEach( ( item, i ) => {
				if ( item.classList.contains( 'is-active' ) ) {
					item.classList.remove( 'is-active' );
				}
				if ( item.classList.contains( 'is-next' ) ) {
					item.classList.remove( 'is-next' );
				}
				if ( item.classList.contains( 'is-previous' ) ) {
					item.classList.remove( 'is-previous' );
				}

				if ( i === this.current ) {
					item.classList.add( 'is-active' );
				}
			} );

			this.elements.buttons.forEach( ( button, i ) => {
				if ( button.classList.contains( 'is-active' ) ) {
					button.classList.remove( 'is-active' );
				}

				if ( i === this.current ) {
					button.classList.add( 'is-active' );
				}
			} );

			// set next and previous
			let nextId = this.current + 1;
			if ( this.current + 1 === this.elements.items.length ) {
				nextId = 0;
			}

			this.elements.items[nextId].classList.add( 'is-next' );

			let previousId = this.current - 1;
			if ( 0 > ( this.current - 1 ) ) {
				previousId = this.elements.items.length - 1;
			}

			this.elements.items[previousId].classList.add( 'is-previous' );
		}

		checkIfInScreen( currentScrollTop ) {
			const rect = this.el.getBoundingClientRect();
			const scrollTop = currentScrollTop ?? window.pageYOffset;
			const viewportHeight = document.documentElement.clientHeight;
			const rectTopInScreen = ( ( rect.top + scrollTop ) > ( scrollTop - ( viewportHeight / 2 ) ) );
			const rectBottomInScreen = ( ( rect.bottom + scrollTop ) < ( scrollTop + viewportHeight + ( viewportHeight / 2 ) ) );

			if ( rectTopInScreen && rectBottomInScreen ) {
				this.visible = true;
			} else {
				this.visible = false;
			}

			this.emit( 'mr-gallery:update', {
				current: this.current,
				items: this.elements.items,
				visible: this.visible,
			} );
		}

		startInterval() {
			if ( !this.auto || 0 === this.auto || isNaN( this.auto ) ) {
				return;
			}

			this.looper = setInterval( () => {
				this.next();
			}, this.auto );
		}

		destroy() {
			this.stop();
			super.destroy();
		}
	},
} );

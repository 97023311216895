import { defineCustomElement, BaseController, attachMediaAttributes } from '@mrhenry/wp--custom-elements-helpers';
import { createGeoJSONCircle, getCoordinatesWithHorizontalDistanceFromPoint } from '../helpers/mapbox-helpers';

const DISTANCE_FROM_CENTER = 1; // 1km

class HotspotMapController extends BaseController {
	resolve() {
		return Promise.all( [
			this.whenMediaMatches(),
			super.resolve(),
		] );
	}

	init() {
		if ( !this.lng ) {
			this.missing_attributes = true;

			return;
		}

		if ( !this.lat ) {
			this.missing_attributes = true;

			return;
		}

		const mapboxVersion = 'v1.1.0';

		const loadJS = function() {
			return new Promise( ( resolve, reject ) => {
				const script = document.createElement( 'script' );
				script.src = `https://api.mapbox.com/mapbox-gl-js/${mapboxVersion}/mapbox-gl.js`;
				script.onload = () => {
					return resolve();
				};
				script.onerror = () => {
					return reject( new Error( 'mapbox gl js failed to load' ) );
				};
				script.async = true;

				const first = document.head.getElementsByTagName( 'script' )[0];
				first.parentNode.insertBefore( script, first );
			} );
		};

		const loadCSS = function() {
			return new Promise( ( resolve, reject ) => {
				const link = document.createElement( 'link' );
				link.rel = 'stylesheet';
				link.href = `https://api.mapbox.com/mapbox-gl-js/${mapboxVersion}/mapbox-gl.css`;
				link.onload = () => {
					return resolve();
				};
				link.onerror = () => {
					return reject( new Error( 'mapbox gl css failed to load' ) );
				};

				const first = document.head.getElementsByTagName( 'link' )[0];
				first.parentNode.insertBefore( link, first );
			} );
		};

		return Promise.all( [
			loadJS(),
			loadCSS(),
		] ).then( () => {
			// XXX_SECRET
			window.mapboxgl.accessToken = 'pk.eyJ1IjoibXJoZW5yeSIsImEiOiJjanhhMmV5dzUwanhxNDBwZTl4aGhsdDJ5In0.aLgfr7p-HMm4Ykh8BoPI3A';

			return this.initMap().then( () => {
				this.drawRadius();
				this.drawPushpin();
				this.drawPoints();
			} );
		} ).catch( ( err ) => {
			console.warn( err );

			this.el.style.display = 'none';
		} );
	}

	bind() {
		if ( this.missing_attributes ) {
			return;
		}

		if ( !this.map ) {
			return;
		}

		this.on( 'fix-to-viewport:resize', () => {
			this.map.resize();
		}, document.body );

		const popup = new window.mapboxgl.Popup( {
			closeButton: false,
			closeOnClick: true,
			anchor: 'left',
			offset: [
				10,
				30,
			],
		} );

		const hidePopup = () => {
			if ( popup ) {
				popup.remove();
			}

			this.activeFeatureID = null;
		};

		const showPopup = ( feature ) => {
			if ( this.activeFeatureID === feature.properties.id ) {
				hidePopup();

				return;
			}

			if ( popup ) {
				hidePopup();
			}

			this.activeFeatureID = feature.properties.id;

			const wrapped = `<div class="hotspotsmap__popup">${feature.properties.popup}</div>`;

			popup.setLngLat( feature.geometry.coordinates ).setHTML( wrapped ).addTo( this.map );
		};

		if ( this.geojson && this.geojson.features ) {
			this.geojson.features.forEach( ( hotspot ) => {
				if ( !hotspot || !hotspot.properties || !hotspot.properties.id ) {
					return;
				}

				const hotspotListItem = document.getElementById( hotspot.properties.id );
				if ( !hotspotListItem ) {
					return;
				}

				hotspotListItem.addEventListener( 'click', () => {
					showPopup( hotspot );
				} );
			} );
		}

		this.map.on( 'mouseenter', 'points', () => {
			const canvas = this.map.getCanvas();
			Object.assign( canvas.style, {
				cursor: 'pointer',
			} );
		} );

		this.map.on( 'click', 'points', ( e ) => {
			const canvas = this.map.getCanvas();
			Object.assign( canvas.style, {
				cursor: 'pointer',
			} );

			showPopup( e.features[0] );
		} );

		this.map.on( 'mouseleave', 'points', () => {
			const canvas = this.map.getCanvas();
			Object.assign( canvas.style, {
				cursor: '',
			} );
		} );
	}

	initMap() {
		return new Promise( ( resolve ) => {
			this.map = new window.mapboxgl.Map( {
				container: this.el,
				style: 'mapbox://styles/mrhenry/cjw7uv1p5014l1cpqhlwqchgs',
				center: [
					this.lng,
					this.lat,
				],
				zoom: 14,
				minZoom: 14,
				maxZoom: 14,
			} );

			this.map.boxZoom.disable();
			this.map.doubleClickZoom.disable();
			this.map.dragPan.disable();
			this.map.dragRotate.disable();
			this.map.keyboard.disable();
			this.map.scrollZoom.disable();
			this.map.touchZoomRotate.disable();

			this.map.on( 'load', () => {
				return resolve();
			} );
		} );
	}

	drawPoints() {
		if ( !this.geojson ) {
			return;
		}

		this.map.addLayer( {
			id: 'points',
			type: 'circle',
			source: {
				type: 'geojson',
				data: this.geojson,
			},
			paint: {
				'circle-color': {
					type: 'identity',
					property: 'color',
				},
				'circle-radius': 14,
			},
		} );

		this.map.addLayer( {
			id: 'labels',
			source: {
				type: 'geojson',
				data: this.geojson,
			},
			interactive: false,
			type: 'symbol',
			layout: {
				'text-field': '{label}',
				'text-size': 18,
			},
			paint: {
				'text-color': '#fff',
			},
		} );
	}

	drawPushpin() {
		// create a HTML element for each feature
		const el = document.createElement( 'div' );
		el.className = 'hotspotsmap__pushpin';

		// make a marker for each feature and add to the map
		new window.mapboxgl.Marker( el )
			.setLngLat( [
				this.lng,
				this.lat,
			] )
			.addTo( this.map );
	}

	drawRadius() {
		this.map.addSource( 'outer-circle', createGeoJSONCircle( [
			this.lng,
			this.lat,
		], DISTANCE_FROM_CENTER ) );
		this.map.addSource( 'middle-circle', createGeoJSONCircle( [
			this.lng,
			this.lat,
		], DISTANCE_FROM_CENTER / 3 * 2 ) );
		this.map.addSource( 'inner-circle', createGeoJSONCircle( [
			this.lng,
			this.lat,
		], DISTANCE_FROM_CENTER / 3 ) );

		const labels = {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: getCoordinatesWithHorizontalDistanceFromPoint( [
							this.lng,
							this.lat,
						], DISTANCE_FROM_CENTER / 6 * 5 ),
					},
					properties: {
						color: this.accentColor,
						label: '15 min',
						id: 'outer-circle-label',
					},
				},
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: getCoordinatesWithHorizontalDistanceFromPoint( [
							this.lng,
							this.lat,
						], DISTANCE_FROM_CENTER / 6 * 3 ),
					},
					properties: {
						color: this.accentColor,
						label: '10 min',
						id: 'middle-circle-label',
					},
				},
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: getCoordinatesWithHorizontalDistanceFromPoint( [
							this.lng,
							this.lat,
						], DISTANCE_FROM_CENTER / 6 ),
					},
					properties: {
						color: this.accentColor,
						label: '5 min',
						id: 'inner-circle-label',
					},
				},
			],
		};

		this.map.addLayer( {
			id: 'circle-labels',
			source: {
				type: 'geojson',
				data: labels,
			},
			interactive: false,
			type: 'symbol',
			layout: {
				'text-field': '{label}',
				'text-size': 16,
			},
			paint: {
				'text-color': this.accentColor,
			},
		} );

		this.map.addLayer( {
			id: 'outer-circle',
			type: 'line',
			source: 'outer-circle',
			layout: {},
			paint: {
				'line-color': this.accentColor,
				'line-opacity': 0.6,
			},
		} );

		this.map.addLayer( {
			id: 'middle-circle',
			type: 'line',
			source: 'middle-circle',
			layout: {},
			paint: {
				'line-color': this.accentColor,
				'line-opacity': 0.6,
			},
		} );

		this.map.addLayer( {
			id: 'inner-circle',
			type: 'line',
			source: 'inner-circle',
			layout: {},
			paint: {
				'line-color': this.accentColor,
				'line-opacity': 0.6,
			},
		} );
	}
}

attachMediaAttributes( HotspotMapController );

defineCustomElement( 'mr-hotspotsmap', {
	attributes: [
		'selector',
		{
			attribute: 'lng',
			type: 'number',
		},
		{
			attribute: 'lat',
			type: 'number',
		},
		{
			attribute: 'geojson',
			type: 'json',
		},
		{
			attribute: 'accent-color',
			type: 'string',
		},
	],
	controller: HotspotMapController,
} );
